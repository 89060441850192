import React, { useState } from "react"
import { MyEmployments } from "../../../Utils/Entities"
import Arbeid from "./Arbeid"
import { fetchApi } from "../../../Utils/Api.utils"
import { useAccount, useMsal } from "@azure/msal-react"
import LoadingData from "../../common/graphichs/LoadingData"
import { useQuery } from "react-query"

const Arbeidsforhold = props => {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [hasWork, setHasWork] = useState<boolean>(false)

  const employmentQuery = useQuery(
    "employment",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Contacts/Employments",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        if (
          data?.filter(
            work =>
              work.employer !== undefined ||
              work.employerAlternative !== undefined
          )
        )
          setHasWork(true)
      },
      onError: _error => {},
    }
  )

  employmentQuery?.data?.sort((a, b) => {
    // If both a and b have no endDate, sort by startDate
    if (!a.endDate && !b.endDate) {
      return (
        new Date(b.startDate || "").getTime() -
        new Date(a.startDate || "").getTime()
      )
    }

    // If only a has no endDate, a should come first
    if (!a.endDate) {
      return -1
    }

    // If only b has no endDate, b should come first
    if (!b.endDate) {
      return 1
    }

    // If both a and b have an endDate, sort by startDate
    return (
      new Date(b.startDate || "").getTime() -
      new Date(a.startDate || "").getTime()
    )
  })
  return employmentQuery.isLoading ? (
    <LoadingData />
  ) : (
    <>
      {hasWork ? (
        <div className="max-w-5xl mx-auto divide-y-2 divide-gray-200">
          {employmentQuery.data?.map((work: MyEmployments) => {
            if (
              (work.employer !== undefined ||
                work.employerAlternative !== undefined) &&
              !(
                work.role == 125600000 &&
                (work.endDate === undefined ||
                  new Date(work.endDate) > new Date())
              )
            ) {
              return (
                <div key={work?.id}>
                  <Arbeid work={work} />
                </div>
              )
            }
          })}
        </div>
      ) : (
        <p className="italic flex justify-center mt-3 max-w-md mx-auto text-base text-gray-600 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
          Det finnes ingen oppføringer.
        </p>
      )}
    </>
  )
}

export default Arbeidsforhold
