import React, { useState } from "react"
import { MyEmployments } from "../../../Utils/Entities"
import { fetchApi } from "../../../Utils/Api.utils"
import { useAccount, useMsal } from "@azure/msal-react"
import LoadingData from "../../common/graphichs/LoadingData"
import { useQuery } from "react-query"
import MainArbeid from "./MainArbeid"

const MainArbeidsforhold = ({ activeMainWork, setActiveMainWork }) => {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [hasMainActiveWork, setHasMainActiveWork] = useState<boolean>(false)

  const employmentQuery = useQuery(
    "employment",
    () =>
      fetchApi(
        process.env.GATSBY_API_URL + "/Contacts/Employments",
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        data?.filter(work => {
          if (work?.role == 125600000) {
            const endDate = work?.endDate ? new Date(work.endDate) : undefined
            if (!endDate || endDate > new Date()) {
              setHasMainActiveWork(true)
              setActiveMainWork(work)
            }
          }
        })
      },
      onError: _error => {},
    }
  )

  //   employmentQuery.data?.sort((a, b) => {
  //     return (
  //       new Date(b.startDate || "").getTime() -
  //       new Date(a.startDate || "").getTime()
  //     )
  //   })

  console.log(activeMainWork)

  return employmentQuery.isLoading ? (
    <LoadingData />
  ) : (
    <>
      {hasMainActiveWork ? (
        <div className="max-w-5xl mx-auto divide-y-2 divide-gray-200">
          <div>
            <MainArbeid work={activeMainWork} />
          </div>
        </div>
      ) : (
        <p className="italic flex justify-center mt-3 max-w-md mx-auto text-base text-gray-600 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
          Det finnes ingen oppføringer.
        </p>
      )}
    </>
  )
}

export default MainArbeidsforhold
