import React, { useState, useEffect } from "react"
import { useForm, Controller, set } from "react-hook-form"
import { Account, Employment } from "../../../Utils/Entities"
import { ErrorMessage } from "@hookform/error-message"
import { Link, navigate } from "gatsby"
import ReactDatePicker, {
  registerLocale,
  setDefaultLocale,
} from "react-datepicker"
import nb from "date-fns/locale/nb"
import "react-datepicker/dist/react-datepicker.css"
import { loginRequest } from "../../security/authConfig"
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react"
import { InteractionType } from "@azure/msal-browser"
import { Loading } from "../../../Utils/Loading"
import { fetchApi, patchApi, postApi } from "../../../Utils/Api.utils"
import MyPageLayout2 from "../../common/layouts/myPageLayout2"
import { ErrorComponent } from "../../common/graphichs/ErrorComponent"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import LoadingData from "../../common/graphichs/LoadingData"
import HelperText from "../../common/text/HelperText"
import { classNames } from "../../common/graphichs/misc"
import { Switch } from "@headlessui/react"
import { RadioGroupComponent } from "../../common/radioGroup/RadioGroup"
import {
  Agreementspecialist,
  CheckForClient,
  TypePrivatePractitioner,
  WorkCategory,
  employerTypes,
} from "../../../Utils/optionSet"
registerLocale("nb", nb)
import Select from "react-select"

// type FormValues = Employment

export const NewEmploymentAsIndependent = ({
  roleOption,
  prevEmploymentData,
  setShowLoadingData,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({ shouldUnregister: true })

  const queryClient = useQueryClient()

  // console.log(showLoadingData)

  const [bedrift, setAccounts] = useState<Account[]>()
  const [allWorkPlaces, setAllWP] = useState<Account[]>()
  const [allEmp, setAllEmployers] = useState<Account[]>()
  const [parent, setParent] = useState<string>()
  const [child, setChild] = useState<string>()
  const [position, setPos] = useState<string>()
  const [places, setWorkPlaces] = useState<any[]>()
  const [disableE, setdisableE] = useState<boolean>(false)
  const [disableP, setdisableP] = useState<boolean>(false)
  const [AStedFri, setAStedFri] = useState<boolean>(false)
  const [aGiverFri, setAGiverFri] = useState<boolean>(false)
  const [incidentId, setIncidentId] = useState("")
  const [description, setDescription] = useState("")

  const [positions, setPositions] = useState<any[]>()
  const [showPos, setShowPos] = useState<boolean>(true)
  const [disablePos, setDisablePos] = useState<boolean>(false)
  const [isPAlt, setIsPAlt] = useState<boolean>(false)

  const [invalidDate, setInvalidDate] = useState<boolean>(false)
  const [smedlem, setSMedlem] = useState<boolean>(false)

  const [timer, setTimer] = useState<number>(2500)

  const [disableButton, setDisabledButton] = useState<boolean>(false)
  const [hideCheckboxPlace, setHideCheckPlace] = useState<boolean>(false)

  const [employerType, setEmployerType] = useState<number>(0)

  const [hideWorkplace, setHideWorkplace] = useState<boolean>(false)

  const [employerAndWorkplace, setEmployerAndWorkplace] =
    useState<boolean>(false)

  const [enabled1, setEnabled1] = useState(false)
  const [enabled2, setEnabled2] = useState(false)
  const [enabled3, setEnabled3] = useState(false)
  const [enabled4, setEnabled4] = useState(false)
  const [enabled5, setEnabled5] = useState(false)

  const [workcategory, setWorkcategory] = useState<number>(0)
  const [typePrivatePractitioner, setTypePrivatePractitioner] =
    useState<number>(0)
  const [agreementspecialist, setAgreementspecialist] = useState<number>(0)
  const [operatingsubsidies, setOperatingsubsidies] = useState<number | null>(0)
  const [accountWithOrgNumber, setAccountWithOrgNumber] = useState("")
  const [accountWithOrgNumberExist, setAccountWithOrgNumberExist] =
    useState<boolean>(false)
  const [clientAccountWithOrgNumber, setClientAccountWithOrgNumber] =
    useState("")
  const [employerNotInList, setEmployerNotInList] = useState<boolean>(false)
  const [showEmpAlt, setShowEmpAlt] = useState<boolean>(false)
  const [employerError, setEmployerError] = useState<string>("")

  const [orgNumber, setOrgNumber] = useState<string>("")
  const [clientOrgNumber, setClientOrgNumber] = useState<string>("")
  const [confirmClient, setConfirmClient] = useState<boolean>(false)
  const [zipCode, setZipCode] = useState<string>("")
  const [zipCodeData, setZipCodeData] = useState("")
  const [zipCodeError, setZipCodeError] = useState<boolean>(false)

  const [selectedClientAccount, setSelectedClientAccount] = useState<string>()
  const [worksForClient, setWorksForClient] = useState<string>("")

  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [accountName, setAccountName] = useState(
    account?.name + ", Privatpraksis"
  )

  const [remainingData, setRemainingData] = useState<object>({})

  // Set the predifined values for the form if workcategory === 292460001 && typePrivatePractitioner === 292460000
  useEffect(() => {
    if (workcategory === 292460001 && typePrivatePractitioner === 292460000) {
      setAccountName(account?.name + ", Privatpraksis")
    } else {
      setAccountName("") // Reset
    }
  }, [workcategory, typePrivatePractitioner])

  const eventQuery = useQuery(
    "myEvents",
    async () =>
      fetchApi(
        process.env.GATSBY_API_URL + `/Events/MyEvents`,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        if (data?.eventRegistration?.length > 0) setTimer(6000)
      },
    }
  )

  useEffect(() => {
    const getSub = async () => {
      fetchApi(
        `${process.env.GATSBY_API_URL}/Contacts/Subscriptions`,
        account,
        inProgress,
        instance
      ).then(response => {
        response?.data.forEach(sub => {
          if (
            sub?.parentOrganizationUnit?.name == "Norsk Psykologforening" &&
            sub?.subscriptionTypeName == "Medlemskap" &&
            sub?.stateCode == 0 &&
            sub?.membershipCategory?.name == "Student"
          ) {
            setSMedlem(true)
          }
        })
      })
    }
    getSub()
  }, [])

  const updateEmployer = event => {
    setdisableE(false)
    setAStedFri(false)
    setChild(event.target.value)
    if (event.target.value != "") {
      fetchApi(
        `${process.env.GATSBY_API_URL}/Accounts/${event.target.value}`,
        account,
        inProgress,
        instance
      )
        .then(response => {
          if (response.data.parentEmployerId != undefined) {
            setParent(response.data.parentEmployerId)
            setdisableE(true)
            setAGiverFri(false)
          } else {
            setParent("")
            setdisableE(true)
            setAGiverFri(true)
          }
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      setAStedFri(true)
      if (!enabled1) {
        setAccounts(allEmp)
        setdisableE(false)
      }
    }
  }

  // const mutationNewWork = useMutation(
  //   data => {
  //     return postApi(
  //       `${process.env.GATSBY_API_URL}/Employments`,
  //       data,
  //       account,
  //       inProgress,
  //       instance
  //     ).then(res => {
  //       res.data
  //     })
  //   },
  //   {
  //     onSuccess: data => {
  //       if (prevEmploymentData !== undefined) {
  //         mutationUpdateWork.mutate(prevEmploymentData)
  //       } else {
  //         if (eventQuery?.data?.eventRegistration?.length > 0) {
  //           toast.success(
  //             "Du har en eller flere påmeldinger på kurs, husk å oppdatere fakturamottager på dine deltagelser",
  //             {
  //               position: "top-center",
  //               autoClose: 6000,
  //             }
  //           )
  //         }
  //         window.setTimeout(() => {
  //           navigate("/app/profil/arbeidsforhold")
  //         }, timer)
  //       }
  //     },
  //     onError: _error => {
  //       setShowLoadingData(false)
  //     },
  //     onSettled: () => {},
  //   }
  // )

  // const mutationUpdateWork = useMutation(
  //   data => {
  //     return patchApi(
  //       `${process.env.GATSBY_API_URL}/Employments/${prevEmploymentData?.id}`,
  //       data,
  //       account,
  //       inProgress,
  //       instance
  //     ).then(res => res.data)
  //   },
  //   {
  //     onSuccess: data => {
  //       if (eventQuery?.data?.eventRegistration?.length > 0) {
  //         // if (employment?.role === 125600000) {
  //         toast.success(
  //           "Du har en eller flere påmeldinger på kurs, husk å oppdatere fakturamottager på dine deltagelser",
  //           {
  //             position: "top-center",
  //             autoClose: 6000,
  //           }
  //         )
  //         // }
  //       }
  //       window.setTimeout(() => {
  //         navigate("/app/profil/arbeidsforhold")
  //       }, timer)
  //       console.log("Success")
  //     },
  //     onError: _error => {
  //       setShowLoadingData(false)
  //       toast.error("Det har skjedd en feil, prøv igjen senere", {
  //         position: "top-center",
  //         autoClose: 6000,
  //       })
  //     },
  //     onSettled: () => {},
  //   }
  // )

  const CheckOrgNumber = useQuery(
    "CheckOrgNumber",
    async () =>
      fetchApi(
        process.env.GATSBY_API_URL +
          `/Accounts/AccountNumberSearch/` +
          orgNumber,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: orgNumber.length === 9,
      onSuccess: data => {
        setShowLoadingData(false)
        setAccountWithOrgNumber(data)
        if (data === null || data === "") {
          setAccountWithOrgNumberExist(false)
        } else {
          setAccountWithOrgNumberExist(true)
        }
      },
      onError: error => {
        setShowLoadingData(false)
      },
    }
  )

  // Search for "Organisasjonsnr"
  const findOrgNumber = e => {
    setEmployerAndWorkplace(false)
    setOrgNumber(e.target.value)
    if (e.target.value.length < 9) {
      setAccountWithOrgNumber("")
      setShowEmpAlt(false)
      setClientAccountWithOrgNumber("")
      setZipCode("")
      setZipCodeData("")
      setZipCodeError(false)
      setWorksForClient("")
    }
  }

  const CheckClientOrgNumber = useQuery(
    "CheckCleintOrgNumber",
    async () =>
      fetchApi(
        process.env.GATSBY_API_URL +
          `/Accounts/AccountNumberSearch/` +
          clientOrgNumber,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: clientOrgNumber.length === 9,
      onSuccess: data => {
        console.log(data)
        setClientAccountWithOrgNumber(data)
        setShowLoadingData(false)
      },
      onError: error => {
        setShowLoadingData(false)
      },
    }
  )

  // Search for "Oppdragsgiver" with "Organisasjonsnr"
  const findClientOrgNumber = e => {
    console.log(e.target.value)
    setClientOrgNumber(e.target.value)
    if (e.target.value.length < 9) {
      setClientAccountWithOrgNumber("")
    }
  }

  const PostalCodes = useQuery(
    "CheckPostalCodes",
    async () =>
      fetchApi(
        process.env.GATSBY_API_URL + `/Addresses/PostalCodes/` + zipCode,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      enabled: zipCode.length === 4,
      retry: 0,
      onSuccess: data => {
        console.log(data)
        setZipCodeData(data)
        setZipCodeError(false)
        setShowLoadingData(false)
      },
      onError: error => {
        setZipCodeError(true)
        setShowLoadingData(false)
      },
    }
  )

  // Search for "ZipCode"
  const findZipCode = e => {
    console.log(e.target.value)
    setZipCode(e.target.value)
    if (e.target.value.length < 4) {
      setZipCodeData("")
      setZipCodeError(false)
    }
  }

  // console.log(zipCode)

  const employersQuery = useQuery(
    "employers",
    async () =>
      fetchApi(
        `${process.env.GATSBY_API_URL}/Accounts/Employers`,
        account,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: data => {
        setAccounts(data)
        setAllEmployers(data)
      },
    }
  )

  const updateWorkPlace = selectedOption => {
    setShowPos(false)
    setIsPAlt(false)
    setParent(selectedOption.value)
    setAGiverFri(false)
    setdisableP(false)
    if (selectedOption.value != "") {
      fetchApi(
        `${process.env.GATSBY_API_URL}/Accounts/${selectedOption.value}`,
        account,
        inProgress,
        instance
      )
        .then(response => {
          setSelectedClientAccount(response.data)
          // if (
          //   response?.data?.employerAndWorkplace === false ||
          //   response?.data?.employerAndWorkplace === undefined
          // ) {
          //   if (response.data.workPlaces.length > 0) {
          //     setWorkPlaces(response.data.workPlaces)
          //     setAStedFri(false)
          //     setdisableP(false)
          //     setHideCheckPlace(false)
          //   } else {
          //     setAStedFri(true)
          //     setdisableP(true)
          //     setHideCheckPlace(true)
          //     setChild("")
          //   }
          //   if (response.data.tariff.positions.length > 0) {
          //     setShowPos(true)
          //     setPositions(response.data.tariff.positions)
          //   } else {
          //     setShowPos(false)
          //     setIsPAlt(true)
          //   }
          // } else {
          //   setHideCheckPlace(true)
          // }
        })
        .catch(error => {})
    } else {
      setAGiverFri(true)
      setWorkPlaces(allWorkPlaces)
      setIsPAlt(true)
    }
  }

  const newAccount = useMutation(
    data => {
      return postApi(
        `${process.env.GATSBY_API_URL}/Accounts`,
        data,
        account,
        inProgress,
        instance
      ).then(res => {
        return res.data
      })
    },
    {
      onSuccess: data => {
        console.log(data)
        console.log(remainingData)
        const updatedRemainingData = {
          ...remainingData,
          employer: data,
        }
        newEmployment.mutate(updatedRemainingData)
      },
      onError: _error => {
        setShowLoadingData(false)
        console.log("Error")
      },
      onSettled: () => {},
    }
  )

  const newEmployment = useMutation(
    data => {
      return postApi(
        `${process.env.GATSBY_API_URL}/Employments`,
        data,
        account,
        inProgress,
        instance
      ).then(res => {
        res.data
      })
    },
    {
      onSuccess: data => {
        if (eventQuery?.data?.eventRegistration?.length > 0) {
          toast.success(
            "Du har en eller flere påmeldinger på kurs, husk å oppdatere fakturamottager på dine deltagelser",
            {
              position: "top-center",
              autoClose: 6000,
            }
          )
        }
        if (prevEmploymentData !== undefined) {
          mutationUpdatePrevEmployment.mutate(prevEmploymentData)
        } else {
          mutationUpdateEmploymentStatus.mutate()
          // window.setTimeout(() => {
          //   navigate("/app/profil/arbeidsforhold")
          // }, timer)
        }
      },
      onError: _error => {
        setShowLoadingData(false)
      },
      onSettled: () => {},
    }
  )

  const mutationUpdatePrevEmployment = useMutation(
    data => {
      return patchApi(
        `${process.env.GATSBY_API_URL}/Employments/${prevEmploymentData?.id}`,
        data,
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        mutationUpdateEmploymentStatus.mutate()
      },
      onError: _error => {
        setShowLoadingData(false)
        toast.error("Det har skjedd en feil, prøv igjen senere", {
          position: "top-center",
          autoClose: 6000,
        })
      },
      onSettled: () => {},
    }
  )

  const mutationUpdateEmploymentStatus = useMutation(
    () => {
      return patchApi(
        process.env.GATSBY_API_URL + "/Contacts/me",
        {
          professionallyActiveStatus: 292460000,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        toast.success("Endringene er lagret", {
          position: "top-center",
          autoClose: 6000,
        })
        window.setTimeout(() => {
          navigate("/app/profil/arbeidsforhold")
        }, timer)
      },
      onSettled: () => {},
    }
  )

  // console.log(remainingData)
  // console.log(selectedClientAccount)

  // console.log(invalidDate)

  const mutationCreateIncident = useMutation(
    () => {
      return postApi(
        process.env.GATSBY_API_URL + "/Incidents",
        {
          title: "Oppdragsgiver ikke i listen",
          description: description,
          caseTypeCode: 1,
          caseOriginCode: 3,
          status: 1,
          state: 0,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        setIncidentId(data)
        toast.success(
          "Saken er opprettet! Du vil nå bli videreført til dine saker.",
          {
            position: "top-center",
            autoClose: 5000,
          }
        )
        mutationCreateMessage.mutate()
        queryClient.invalidateQueries(["userIncidentData"])
      },
      onError: _error => {},
      onSettled: () => {
        // setShowLoadingData(false)
      },
    }
  )

  const mutationCreateMessage = useMutation(
    () => {
      return postApi(
        process.env.GATSBY_API_URL + "/Messages",
        {
          subject: "Oppdragsgiver ikke i listen",
          description: description,
          direction: false,
          regardingObjectIncidentId: incidentId,
        },
        account,
        inProgress,
        instance
      ).then(res => res.data)
    },
    {
      onSuccess: data => {
        queryClient.invalidateQueries(["userMessagesData"])
        setShowLoadingData(false)
        // if (!withFile) {
        setTimeout(() => {
          navigate("/app/dialog/minesaker")
        }, 4000)
        // }
      },
      onError: _error => {
        setShowLoadingData(false)
      },
      onSettled: () => {},
    }
  )

  const postWork = async data => {
    console.log(data)
    //Check if endate have a value. If it is undefined or null, remove it from the object
    if (
      data.employment?.endDate === undefined ||
      data.employment?.endDate === null
    ) {
      delete data.employment?.endDate
    }

    if (roleOption !== undefined) {
      data.employment.role = roleOption
    }

    //Check if the user have selected a valid client
    if (
      employerAndWorkplace === false &&
      showEmpAlt === false &&
      worksForClient === "Yes"
    ) {
      setEmployerError("Du må velge en oppdragsgiver")
    }
    //Check if startDate is before endDate
    else if (data.employment?.startDate >= data.employment?.endDate) {
      setInvalidDate(true)
    } else {
      setInvalidDate(false)

      // Check if the user works for a client
      if (worksForClient === "Yes") {
        // If client employment is selected from the list or not. showEmpAlt = true means that the employer client is not in the list
        if (showEmpAlt) {
          data.employment.clientsName = clientAccountWithOrgNumber?.name
          data.employment.clientOrgNo =
            clientAccountWithOrgNumber?.accountNumber
          data.employment.clientAddress =
            clientAccountWithOrgNumber?.address1_Line1
          data.employment.clientZipCodeId =
            clientAccountWithOrgNumber?.zipCodeId
          data.employment.clientId = clientAccountWithOrgNumber?.id
        } else {
          data.employment.clientsName = selectedClientAccount?.name
          data.employment.clientOrgNo = selectedClientAccount?.accountNumber
          data.employment.clientAddress = selectedClientAccount?.address1_Line1
          data.employment.clientZipCodeId = selectedClientAccount?.zipCodeId
          data.employment.clientId = selectedClientAccount?.id
        }
      }

      // Remove undefined properties from data.employment
      Object.keys(data.employment).forEach(key => {
        if (data.employment[key] === undefined) {
          delete data.employment[key]
        }
      })

      // Privatpraksis + ENK
      if (workcategory === 292460001 && typePrivatePractitioner === 292460000) {
        //Check if there is an account with the orgNumber
        if (accountWithOrgNumber === null) {
          data.account.name = account?.name + ", Privatpraksis"
          data.account.accountNumber = orgNumber
          data.account.countryCodeId = process.env.GATSBY_COUNTRY_CODE
          data.account.address1_Line2 = data.account.address1_Line1
          data.account.zipCodeId = zipCodeData?.id
          data.account.employer = false
          data.account.tariffId = process.env.GATSBY_TARIFF_INDEPENDENT
        } else {
          data.employment.employer = accountWithOrgNumber?.id
        }

        //Check if clientAccountWithOrgNumber is not ""
        if (clientAccountWithOrgNumber !== "") {
          data.employment.clientId = clientAccountWithOrgNumber?.id
        }

        //Check if aggremmentspecialist is "yes" or "no"
        if (agreementspecialist === 1) {
          data.employment.rightOfReimbursment = true
        } else if (agreementspecialist === 0) {
          data.employment.rightOfReimbursment = false
        }

        setRemainingData(data.employment)
        console.log(data)

        //Check if an incidnet should be created
        if (clientAccountWithOrgNumber === null) {
          // const formattedData = formatData(data)
          console.log("Opprett sak")
          // setDescription(formattedData)
        } else if (accountWithOrgNumberExist === true) {
          console.log("Ikke opprett ny forbindelse")
          setShowLoadingData(true)
          newEmployment.mutate(data.employment) // Post new employment
          console.log(data)
        } else {
          console.log("Opprett ny forbindelse")
          console.log(data)
          setShowLoadingData(true)
          newAccount.mutate(data.account) // Post new account
          // {
          //   // Run this fetch and send data.firmName as data to check for existing account with "full name of the user, Privatpraksis"
          //   fetchApi(
          //     process.env.GATSBY_API_URL +
          //       `/Accounts/AccountNameSearch/` +
          //       data.account.name,
          //     account,
          //     inProgress,
          //     instance
          //   )
          //     .then(response => {
          //       if (response.data.accountNumber === undefined) {
          //         console.log(response.data)
          //         console.log("No data")
          //         // newAccount.mutate({accountValues: data.account, employemntValues: data.account}) // Post new account
          //       } else {
          //         console.log(response.data)
          //         console.log("Found data")
          //         data.employment.employer = response.data.id
          //         newEmployment.mutate(data.employment) // Post new employment
          //       }
          //     })
          //     .catch(() => {
          //       toast.error("Det skjedde en feil, prøv igjen senere", {
          //         position: "top-center",
          //         autoClose: 6000,
          //       })
          //     })
          // }
        }
      }

      // Privatpraksis + Selvstenig AS
      if (workcategory === 292460001 && typePrivatePractitioner === 292460001) {
        //Check if there is an account with the orgNumber
        if (accountWithOrgNumber === null) {
          data.account.accountNumber = orgNumber
          data.account.countryCodeId = process.env.GATSBY_COUNTRY_CODE
          data.account.address1_Line2 = data.account.address1_Line1
          data.account.zipCodeId = zipCodeData?.id
          data.account.employer = true
          data.account.tariffId = process.env.GATSBY_TARIFF_IN_AS
        } else {
          data.employment.employer = accountWithOrgNumber?.id
        }

        //Check if clientAccountWithOrgNumber is not ""
        if (clientAccountWithOrgNumber !== "") {
          data.employment.clientId = clientAccountWithOrgNumber?.id
        }

        //Check if aggremmentspecialist is "yes" or "no"
        if (agreementspecialist === 1) {
          data.employment.rightOfReimbursment = true
        } else if (agreementspecialist === 0) {
          data.employment.rightOfReimbursment = false
        }

        if (accountWithOrgNumberExist === true) {
          console.log("Ikke opprett ny forbindelse")
          setShowLoadingData(true)
          newEmployment.mutate(data.employment)
        } else {
          console.log("Opprett ny forbindelse")
          setShowLoadingData(true)
          newAccount.mutate(data.account)
        }
      }

      // Selvstendig næringsdrivende
      if (workcategory === 292460002) {
        //Check if there is an account with the orgNumber
        if (accountWithOrgNumber === null) {
          data.account.accountNumber = orgNumber
          data.account.countryCodeId = process.env.GATSBY_COUNTRY_CODE
          data.account.address1_Line2 = data.account.address1_Line1
          data.account.zipCodeId = zipCodeData?.id
          data.account.employer = true //Check true or false
          if (typePrivatePractitioner === 292460000) {
            data.account.tariffId = process.env.GATSBY_TARIFF_INDEPENDENT
          } else if (typePrivatePractitioner === 292460001) {
            data.account.tariffId = process.env.GATSBY_TARIFF_IN_AS
          }
        } else {
          data.employment.employer = accountWithOrgNumber?.id
        }

        //Check if clientAccountWithOrgNumber is not ""
        if (clientAccountWithOrgNumber !== "") {
          data.employment.clientId = clientAccountWithOrgNumber?.id
        }

        if (accountWithOrgNumberExist === true) {
          console.log("Ikke opprett ny forbindelse")
          setShowLoadingData(true)
          newEmployment.mutate(data.employment)
        } else {
          console.log("Opprett ny forbindelse")
          setShowLoadingData(true)
          newAccount.mutate(data.account)
        }
      }
    }
    console.log(description)
  }

  console.log(description)

  const onClickEmployerNotInList = () => {
    if (showEmpAlt === true) {
      setEmployerError("")
      setShowEmpAlt(false)
      setClientAccountWithOrgNumber("")
    } else {
      setEmployerError("")
      setShowEmpAlt(true)
    }
  }

  const onClickAgreementspecialist = e => {
    setAgreementspecialist(parseInt(e.target.value))
  }

  const onClickWorksForClient = e => {
    setWorksForClient(e.target.value)
  }

  // console.log("workcategory: " + workcategory)
  // console.log("typePrivatePractitioner: " + typePrivatePractitioner)
  // console.log(agreementspecialist)
  // console.log(clientAccountWithOrgNumber === "")

  return (
    <div>
      <div className="">
        <form
          method="POST"
          onSubmit={handleSubmit(postWork)}
          className="space-y-8 "
        >
          <div className="space-y-8 sm:space-y-5">
            <div className="space-y-6sm:space-y-5">
              <div className="space-y-6 sm:space-y-5">
                {smedlem ? (
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                    <label
                      htmlFor="role"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Rolle:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <select
                        {...register("employment.role")}
                        name="employment.role"
                        id="employment.role"
                        key="employment.role"
                        disabled={true}
                        className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md bg-gray-50"
                      >
                        {/* <option value={125600000}>Hovedarbeidsgiver</option> */}
                        <option value={125600001}>Biarbeidsgiver</option>
                      </select>
                    </div>
                  </div>
                ) : (
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                    <label
                      htmlFor="role"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Rolle:
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <select
                        {...register("employment.role")}
                        name="employment.role"
                        id="employment.role"
                        key="employment.role"
                        disabled={roleOption === undefined ? false : true}
                        className=" disabled:bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                      >
                        {roleOption === undefined ? (
                          <>
                            <option value={125600000}>Hovedarbeidsgiver</option>
                            <option value={125600001}>Biarbeidsgiver</option>
                          </>
                        ) : (
                          <option value={roleOption}>
                            {roleOption === 125600000
                              ? "Hovedarbeidsgiver"
                              : "Biarbeidsgiver"}
                          </option>
                        )}
                      </select>
                    </div>
                  </div>
                )}

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                  <label
                    htmlFor="workcategory"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Er du privatpraktiserende med klinisk praksis, eller driver
                    du som selvstendig næringsdrivende uten klinisk praksis:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <select
                      {...register("employment.workcategory", {
                        required: true,
                      })}
                      name="employment.workcategory"
                      id="employment.workcategory"
                      key="employment.workcategory"
                      defaultValue={""}
                      onChange={e => {
                        setWorkcategory(parseInt(e.target.value))
                        setClientAccountWithOrgNumber("")
                        setShowEmpAlt(false)
                      }}
                      className=" disabled:bg-gray-200 disabled:text-opacity-50 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                    >
                      <option value="" disabled>
                        -- Velg en type --
                      </option>
                      {WorkCategory?.map(p => (
                        <option key={p.id} value={p.id}>
                          {p.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/* Choose a workcategory */}
                {workcategory !== 0 && (
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                    <label
                      htmlFor="typePrivatePractitioner"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      {workcategory === 292460001 &&
                        "Driver du privatpraksis som enkeltpersonsforetak eller som ansatt i eget AS:"}
                      {workcategory === 292460002 &&
                        "Driver du et enkeltpersonsforetak eller som ansatt i eget AS:"}
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <select
                        {...register("employment.typePrivatePractitioner", {
                          required: true,
                        })}
                        name="employment.typePrivatePractitioner"
                        id="employment.typePrivatePractitioner"
                        key="employment.typePrivatePractitioner"
                        defaultValue={""}
                        onChange={e => {
                          setTypePrivatePractitioner(parseInt(e.target.value))
                          setClientAccountWithOrgNumber("")
                          setShowEmpAlt(false)
                        }}
                        className=" disabled:bg-gray-200 disabled:text-opacity-50 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                      >
                        <option value="" disabled>
                          -- Velg en type --
                        </option>
                        {TypePrivatePractitioner?.map(p => (
                          <option key={p.id} value={p.id}>
                            {p.title}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}

                {/* Choose typePrivatePractitioner */}
                {typePrivatePractitioner !== 0 && (
                  <>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                      <label
                        htmlFor="orgNumber"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Organisasjonsnr:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <input
                          required
                          type="text"
                          onChange={findOrgNumber}
                          className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                          maxLength={9}
                          minLength={9}
                        />
                      </div>
                    </div>
                    {/* show if accountWithOrgNumber is not null or "" */}
                    {accountWithOrgNumber === "" ||
                    accountWithOrgNumber === null ? null : (
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                        <label
                          htmlFor="Organisasjonsnr er koblet mot"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Organisasjonsnr er koblet mot:
                        </label>
                        <div className="mt-1 pt-2 sm:mt-0 sm:col-span-2">
                          <p>{accountWithOrgNumber?.name}</p>
                        </div>
                      </div>
                    )}
                    {/* if accountWithOrgNumber is null, show message */}
                    {accountWithOrgNumber === null ? (
                      <p className="block text-sm italic font-normal sm:col-span-2 text-meny-700 sm:mt-px sm:pt-2 sm:col-start-3">
                        {workcategory == 292460001 &&
                        typePrivatePractitioner == 292460000
                          ? "Ditt enkeltpersonsforetak"
                          : "Din virksomhet"}{" "}
                        er ikke registrert hos oss, og vil bli automatisk
                        opprettet når du har oppgitt nok informasjon.
                      </p>
                    ) : null}
                  </>
                )}
                {/* Register new Account if accountWithOrgNumber is null */}
                {accountWithOrgNumber === null && (
                  <>
                    {workcategory === 292460001 &&
                      typePrivatePractitioner === 292460000 && (
                        <div
                          className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5"
                          key={0}
                          id="0"
                        >
                          <label
                            htmlFor="account.name"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            Firmanavn:
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                              type="text"
                              {...register("account.name")}
                              value={accountName}
                              name="account.name"
                              id="account.name"
                              disabled
                              className=" disabled:bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                      )}
                    {((workcategory === 292460001 &&
                      typePrivatePractitioner === 292460001) ||
                      workcategory === 292460002) && (
                      <div
                        className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5"
                        key={1}
                        id="1"
                      >
                        <label
                          htmlFor="account.name"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Navn fra Brønnøysundregistrene:
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            type="text"
                            {...register("account.name")}
                            name="account.name"
                            id="account.name"
                            required
                            className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    )}
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                      <label
                        htmlFor="orgNumber"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Organisasjonsnr:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <input
                          type="text"
                          value={orgNumber}
                          disabled
                          className="disabled:bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                      <label
                        htmlFor="account.address1_Line1"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Adresse:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <input
                          type="text"
                          {...register("account.address1_Line1")}
                          required
                          name="account.address1_Line1"
                          id="account.address1_Line1"
                          className="disabled:bg-gray-200 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                      <label
                        htmlFor="account.zipCodeId"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Postnummer:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2 flex flex-col space-y-2">
                        <input
                          type="text"
                          {...register("account.zipCodeId")}
                          required
                          name="account.zipCodeId"
                          id="account.zipCodeId"
                          maxLength={4}
                          minLength={4}
                          onChange={findZipCode}
                          className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        />
                        {zipCodeError && (
                          <p className="text-red-500">Ugyldig postnummer</p>
                        )}
                        {zipCodeData !== "" && (
                          <div className=" ml-2 mt-1 ">
                            <p>{zipCodeData?.postalPlace}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {/* if "Klinisk praksis" is selected and orgNumber registered. Ask for "Avtalespesialist" */}
                {orgNumber.length === 9 && workcategory === 292460001 && (
                  <>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                      <label
                        htmlFor="employment.agreementspecialist"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Er du avtalespesialist:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <select
                          {...register("employment.agreementspecialist", {
                            required: true,
                            valueAsNumber: true,
                          })}
                          name="employment.agreementspecialist"
                          id="employment.agreementspecialist"
                          key="employment.agreementspecialist"
                          defaultValue={""}
                          onChange={onClickAgreementspecialist}
                          className=" disabled:bg-gray-200 disabled:text-opacity-50 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        >
                          <option value="" disabled>
                            -- Velg en --
                          </option>
                          {Agreementspecialist?.map(p => (
                            <option key={p.id} value={p.id}>
                              {p.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {agreementspecialist === 1 && (
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                        <label
                          htmlFor="employment.operatingGrants"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Oppgi driftstilskudd i % (oppgi 0 hvis du ikke har
                          driftstilskudd):
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            type="number"
                            {...register("employment.operatingGrants", {
                              required: true,
                              valueAsNumber: true,
                            })}
                            max={100}
                            name="employment.operatingGrants"
                            id="employment.operatingGrants"
                            className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
                {orgNumber.length === 9 && (
                  <>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                      <label
                        htmlFor="worksForClient"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Jobber du for en oppdragsgiver?:
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <select
                          required
                          defaultValue={""}
                          onChange={onClickWorksForClient}
                          className=" disabled:bg-gray-200 disabled:text-opacity-50 block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        >
                          <option value="" disabled>
                            -- Velg en --
                          </option>
                          {CheckForClient?.map(p => (
                            <option key={p.id} value={p.id}>
                              {p.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {orgNumber.length === 9 && worksForClient === "Yes" && (
                      <>
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                          <label
                            htmlFor="employment.clientId"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            Din oppdragsgiver:
                          </label>
                          <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <Select
                              name="employment.clientId"
                              id="employment.clientId"
                              key="employment.clientId"
                              isSearchable
                              options={bedrift?.map(a => ({
                                value: a.id,
                                label: a.name,
                              }))}
                              isDisabled={showEmpAlt}
                              placeholder="-- Oppdragsgivere --"
                              value={
                                parent === null || parent === undefined
                                  ? null
                                  : bedrift?.find(
                                      option => option.name === parent
                                    )
                              }
                              onChange={option => {
                                updateWorkPlace(option)
                                setEmployerAndWorkplace(true)
                                setEmployerError("")
                                // setValue("employment.clientId", option.value)
                              }}
                              className=" block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                            ></Select>
                          </div>
                        </div>
                        {employerError !== "" && (
                          <p className="text-red-500">{employerError}</p>
                        )}
                        {/* If workplace is not in the list */}
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                          <label
                            htmlFor="checkbox"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            Min oppdragsgiver er ikke i listen:
                          </label>
                          <div className="pt-4 sm:pt-2.5 h-5 w-5">
                            <input
                              type="checkbox"
                              checked={showEmpAlt}
                              onChange={onClickEmployerNotInList}
                              className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                        {/* Search with orgnr */}
                        {showEmpAlt && (
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                            <label
                              htmlFor="accountNumber"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Organisasjonsnr for oppdragsgiver:
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <input
                                required
                                type="text"
                                onChange={findClientOrgNumber}
                                className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                                maxLength={9}
                                minLength={9}
                              />
                            </div>
                          </div>
                        )}
                        {/* show if clientAccountWithOrgNumber is not null or "" */}
                        {clientAccountWithOrgNumber === "" ||
                        clientAccountWithOrgNumber === null ? null : (
                          <>
                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                              <label
                                htmlFor="Organisasjonsnr er koblet mot"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                              >
                                Oppdragsgiver:
                              </label>
                              <div className="mt-1 pt-2 sm:mt-0 sm:col-span-2">
                                <p>{clientAccountWithOrgNumber?.name}</p>
                              </div>
                            </div>
                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                              <label
                                htmlFor="checkbox"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                              >
                                Bekreft oppdragsgiver:
                              </label>
                              <div className="pt-4 sm:pt-2.5 h-5 w-5">
                                <input
                                  type="checkbox"
                                  required
                                  onChange={() =>
                                    setConfirmClient(!confirmClient)
                                  }
                                  className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                                />
                              </div>
                            </div>
                          </>
                        )}
                        {/* if clientAccountWithOrgNumber is null, show message */}
                        {clientAccountWithOrgNumber === null ? (
                          <p className="block text-sm italic font-normal sm:col-span-2 text-meny-700 sm:mt-px sm:pt-2 sm:col-start-3">
                            Vi har ikke registrert denne virksomheten i vårt
                            system. Dersom du går videre så vil det opprettes en
                            sak hos oss for å ferdigstille registrering
                          </p>
                        ) : null}
                      </>
                    )}
                  </>
                )}
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                  <label
                    htmlFor="partTimePosition"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Prosentvis stiling:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      type="number"
                      {...register("employment.partTimePosition", {
                        min: {
                          value: 1,
                          message: "Verdien må være større enn 0",
                        },
                        max: {
                          value: 100,
                          message: "Verdien må være mindre enn eller lik 100",
                        },
                        required: true,
                        valueAsNumber: true,
                      })}
                      name="employment.partTimePosition"
                      placeholder="1-100"
                      defaultValue={0}
                      id="employment.partTimePosition"
                      className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="employment.partTimePosition"
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    />
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                  <label
                    htmlFor="startDate"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Start dato:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Controller
                      control={control}
                      name="employment.startDate"
                      defaultValue={new Date()}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <ReactDatePicker
                          required={true}
                          locale="nb"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          dateFormat="dd/MM/yyyy"
                          className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  sm:pt-5">
                  <label
                    htmlFor="endDate"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Slutt dato:
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Controller
                      control={control}
                      name="employment.endDate"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <ReactDatePicker
                          locale="nb"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          dateFormat="dd/MM/yyyy"
                          className="block w-full shadow-sm focus:ring-meny-700 focus:border-meny-500 sm:text-sm border-gray-300 rounded-md"
                        />
                      )}
                    />
                  </div>
                  <div className="mb-2 flex justify-center">
                    {invalidDate ? (
                      <p className="text-red-500">
                        Start dato kan ikke være etter slutt dato.
                      </p>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
                <div className="pt-5 flex justify-end">
                  <button
                    type="button"
                    // disabled={disableButton}
                    onClick={() => navigate("/app/profil/arbeidsforhold")}
                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                  >
                    Tilbake
                  </button>
                  <button
                    type="submit"
                    disabled={zipCodeError}
                    className="disabled:bg-gray-200 disabled:text-opacity-50 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-meny-700 hover:bg-meny-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-meny-700"
                  >
                    Lagre
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
