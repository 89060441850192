import { Router } from "@reach/router"
import { withPrefix } from "gatsby"
import React from "react"

import { BaseRole } from "../components/common/navbar/BaseRole"
import { EventRole } from "../components/common/navbar/EventRole"
import { MemberRole } from "../components/common/navbar/MemberRole"
import { BaseWithJournalRole } from "../components/common/navbar/BaseWithJournalRole"
import ExternalCourseProvider from "../components/mypage/dialog/ExternalCourseProvider"
import Incident from "../components/mypage/dialog/Incident"
import MyIncidents from "../components/mypage/dialog/MyIncidents"
import MyMessages from "../components/mypage/dialog/MyMessages"
import NewIncident from "../components/mypage/dialog/NewIncident"
import Event from "../components/mypage/event"
import Confirmation from "../components/mypage/event/Confirmation"
import EventAdmin from "../components/mypage/event/EventAdmin"
import EventCalendar from "../components/mypage/event/EventCalendar"
import EventRegistrationList from "../components/mypage/event/EventRegistrationList"
import MyEventRegistation from "../components/mypage/event/MyEventRegistration"
import MyEvents from "../components/mypage/event/MyEvents"
import Registration from "../components/mypage/event/Registration"
import SessionAdmin from "../components/mypage/event/SessionAdmin"
import SessionRegistrationList from "../components/mypage/event/SessionRegistrationList"
import MembershipBenefits from "../components/mypage/membership/applications/MembershipBenefits"
import ReducedContingent from "../components/mypage/membership/applications/ReducedContingent"
import SendChange from "../components/mypage/membership/applications/SendChange"
import StudentToOrdinary from "../components/mypage/membership/applications/StudentToOrdinary"
import ChangeMembership from "../components/mypage/membership/ChangeMembership"
import MyMemberships from "../components/mypage/membership/MyMemberships"
import NewMember from "../components/mypage/membership/NewMember"
import ContactInfo from "../components/mypage/profil/ContactInfo"
import EditContact from "../components/mypage/profil/EditContact"
import EditWork from "../components/mypage/profil/EditWork"
import Educations from "../components/mypage/profil/Education"
import Employments from "../components/mypage/profil/Employments"
import Journal from "../components/mypage/profil/Journal"
import NewEmployment from "../components/mypage/profil/NewEmployment"
import NewSpecializationActivity from "../components/mypage/specialityeducation/NewSpecializationActivity"
import SpecialityEducation from "../components/mypage/specialityeducation/SpecialityEducation"
import SpecialityPogram from "../components/mypage/specialityeducation/SpecialityPogram"
import SpecializationActivity from "../components/mypage/specialityeducation/SpecializationActivity"
import Templates from "../components/mypage/specialityeducation/Templates"
// import NewEmploymentContent from "../components/mypage/membership/applications/EmploymentsStudent"
import AdminCheckin from "../components/mypage/event/AdminCheckin"
import EditCheckin from "../components/mypage/event/EditCheckin"
import Home from "../components/mypage/Home"
import CloseMembership from "../components/mypage/membership/applications/CloseMembership"
import EditNewWork from "../components/mypage/membership/applications/EditNewWork"
import EmploymentsStudent from "../components/mypage/membership/applications/EmploymentsStudent"
import GetConsents from "../components/mypage/membership/applications/GetConsents"
import OrdinaryToPension from "../components/mypage/membership/applications/OrdinaryToPension"
import PensionerUpload from "../components/mypage/membership/applications/PensionerUpload"
import RulesApplication from "../components/mypage/membership/applications/RulesApplication"
import { NotMemberRole } from "../components/common/navbar/NotMemberRole"
import EditSpecializationActivity from "../components/mypage/specialityeducation/EditSpecializationActivity"
import SpeakerRequestResponse from "../components/mypage/event/EventAdmin/SpeakerRequestResponse"
import EditPracticeHour from "../components/mypage/specialityeducation/EditPracticeHour"
import NewPracticeDetail from "../components/mypage/specialityeducation/NewPracticeDetail"
import ExpenseReports from "../components/mypage/dialog/utlegg/ExpenseReports"
import Report from "../components/mypage/dialog/utlegg/Report"
import NewExpenseReport from "../components/mypage/dialog/utlegg/NewExpenseReport"
import AddNewLines from "../components/mypage/dialog/utlegg/AddNewLines"
import { PrevOrCurrMemberRole } from "../components/common/navbar/PrevOrCurrMemberRole"
import EditExpenseReport from "../components/mypage/dialog/utlegg/EditExpenseReport"
import Documents from "../components/mypage/dialog/Documents"
import ConvertToBiEmployment from "../components/mypage/profil/ConvertToBiEmployment"
import EndAllEmployements from "../components/mypage/membership/applications/EndAllEmployments"
// import EuropsyCertificate from "../components/mypage/profil/EuropsyCertificate"

//TODO: create route for medlem & course, if user doesnt have the role,
//navigate to origin page without using window.location if possible, if not, navigate to landingpage
//eksempel of implemetatoin: <GroupRoute1 path="produksjonsplan" component={ProductionPlan} />
const Minside = () => {
  // const { webRoles, setWebRoles } = useContext(WebRoleContext)
  // console.log(webRoles)
  return (
    <>
      <div>
        <Router basepath={withPrefix("/app")}>
          {/* /app */}
          <Home path="/" component={Minside} />
          <ContactInfo path="/profil" component={Minside} />

          {/* /profil */}
          {/* <ContactInfo path="profil/kontaktinfo" /> */}
          <BaseRole path="profil/endrebruker" Component={EditContact} />
          <BaseRole path="profil/arbeidsforhold" Component={Employments} />
          <BaseRole path="profil/endrearbeid" Component={EditWork} />
          <BaseRole path="profil/nyttarbeid" Component={NewEmployment} />
          <BaseRole path="profil/utdanning" Component={Educations} />
          <BaseRole
            path="profil/endre-til-biarbeidsforhold"
            Component={ConvertToBiEmployment}
          />
          {/* <BaseRole
            path="profil/sertifisering"
            Component={EuropsyCertificate}
          /> */}
          <BaseWithJournalRole path="profil/tidsskrift" Component={Journal} />

          {/* /dialog */}
          <BaseRole path="dialog/nysak" Component={NewIncident} />
          <BaseRole path="dialog/minesaker" Component={MyIncidents} />
          <BaseRole path="dialog/sak" Component={Incident} />
          <BaseRole path="dialog/minemeldinger" Component={MyMessages} />

          {/* /Membership */}
          <NotMemberRole path="medlemskap/nymedlemskap" Component={NewMember} />
          <PrevOrCurrMemberRole
            path="medlemskap/minemedlemskap"
            Component={MyMemberships}
          />
          <MemberRole
            path="medlemskap/endremedlemskap"
            Component={ChangeMembership}
          />
          <MemberRole
            path="medlemskap/applications/studentordinaer"
            Component={StudentToOrdinary}
          />
          <MemberRole
            path="medlemskap/applications/nedsattkontigent"
            Component={ReducedContingent}
          />
          <MemberRole
            path="/medlemskap/applications/ordinarytopension"
            Component={OrdinaryToPension}
          />
          <MemberRole
            path="/medlemskap/applications/editnewwork"
            Component={EditNewWork}
          />

          <MemberRole
            path="/medlemskap/applications/avsluttarbeidsforhold"
            Component={EndAllEmployements}
          />

          <MemberRole
            path="medlemskap/applications/employmentStudent"
            Component={EmploymentsStudent}
          />
          <MemberRole
            path="medlemskap/applications/sendchange"
            Component={SendChange}
          />
          <MemberRole
            path="medlemskap/application/docupload"
            Component={PensionerUpload}
          />
          <MemberRole
            path="medlemskap/applications/getconcents"
            Component={GetConsents}
          />
          <MemberRole
            path="medlemskap/applications/CloseMembership"
            Component={CloseMembership}
          />
          <MemberRole
            path={"/medlemskap/applications/rules"}
            Component={RulesApplication}
          />
          <MemberRole
            path={"/medlemskap/fordeler"}
            Component={MembershipBenefits}
          />

          {/* <MemberRole
          path=""
          /> */}
          {/* <MyMemberships path="medlemskap/minemedlemskap" /> */}
          {/* <ChangeMembership path="medlemskap/endremedlemskap" /> */}
          {/* <StudentToOrdinary path="medlemskap/applications/studentordinaer" /> */}
          {/* <ReducedContingent path="medlemskap/applications/nedsattkontigent" /> */}
          {/* <MembershipBenefits path="medlemskap/applications/MembershipBenefits" /> */}
          {/* <OrdinaryToPension path="/medlemskap/applications/ordinarytopension" /> */}
          {/* <OrdinaryToPension path="/medlemskap/applications/ordinarytopension" /> */}

          {/* /Event */}
          <BaseRole path="kurs/kurskalender" Component={EventCalendar} />
          <BaseRole path="kurs" Component={Event} />
          <BaseRole path="kurs/innmelding" Component={Registration} />
          <BaseRole path="kurs/bekreftelse" Component={Confirmation} />
          <BaseRole
            path="kurs/kursleverandor"
            Component={ExternalCourseProvider}
          />

          <BaseRole path="kurs/minekurs" Component={MyEvents} />
          <BaseRole
            path="kurs/mineKurs/kursRegistrering"
            Component={MyEventRegistation}
          />
          <EventRole path="kurs/administrasjon" Component={EventAdmin} />
          {/* <EventRole
            path="kurs/administrasjon/delmote"
            Component={SessionAdmin}
          /> */}
          <EventRole
            path="kurs/administrasjon/kursdeltager"
            Component={EventRegistrationList}
          />
          <EventRole
            path="kurs/administrasjon/delmotedeltager"
            Component={SessionRegistrationList}
          />
          <EventRole
            path="kurs/administrasjon/oppmote"
            Component={AdminCheckin}
          />
          <EventRole
            path="kurs/administrasjon/rediger"
            Component={EditCheckin}
          />

          <EventRole
            path="kurs/administrasjon/foresporsel"
            Component={SpeakerRequestResponse}
          />

          {/* /Spesialst godkjenning */}
          <BaseRole
            path="kurs/specialityeducation/maler"
            Component={Templates}
          />
          <BaseRole
            path="kurs/specialityeducation/utdanning"
            Component={SpecialityEducation}
          />
          <BaseRole
            path="kurs/specialityeducation/program"
            Component={SpecialityPogram}
          />
          <BaseRole
            path="kurs/specialityeducation/newactivity"
            Component={NewSpecializationActivity}
          />
          <BaseRole
            path="kurs/specialityeducation/activity"
            Component={SpecializationActivity}
          />
          <BaseRole
            path="kurs/specialityeducation/endreAktivitet"
            Component={EditSpecializationActivity}
          />
          <BaseRole
            path="kurs/specialityeducation/activity/endrePraksis"
            Component={EditPracticeHour}
          />
          <BaseRole
            path="kurs/specialityeducation/nyPraksisdetalj"
            Component={NewPracticeDetail}
          />

          <BaseRole path="dialog/mineutlegg" Component={ExpenseReports} />
          <BaseRole path="dialog/utlegg" Component={Report} />
          <BaseRole path="dialog/nyttutlegg" Component={NewExpenseReport} />
          <BaseRole path="dialog/endrenylinje" Component={AddNewLines} />
          <BaseRole path="dialog/endreutlegg" Component={EditExpenseReport} />
          <BaseRole path="dialog/dokumenter" Component={Documents} />
        </Router>
      </div>
    </>
  )
}

export default Minside
