import { Fragment, useState } from "react"
import { Menu, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"
import React from "react"
import { classNames } from "../graphichs/misc"

export const DropDownPresetValues = ({ options, setParentValue, text }) => {
  const [buttonText, setButtonText] = useState(text)
  const OptionChosen = e => {
    setParentValue(e.target.value)
    setButtonText(e.target.innerHTML)
  }

  return (
    <Menu
      as="div"
      className="shadow-sm focus:ring-meny-700 focus:border-meny-500 block w-full sm:text-sm border-gray-300 rounded-md"
    >
      <div>
        <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-meny-700">
          {buttonText}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {options.map(item => (
              <Menu.Item key={item.value}>
                {({ active }) => (
                  <button
                    type="button"
                    value={item.value}
                    onClick={e => OptionChosen(e)}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    {item.label}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
