export const typePracticeConsultant = new Map([
  [292460000, "Ledelse og lederutvikling"],
  [292460001, "Organisasjonsutvikling"],
  [292460002, "Veiledning og utviklingsarbeid"],
  [292460003, "Personalutvikling"],
  [292460004, "Konflikthåndtering"],
  [292460005, "Arbeidsmiljøspørsmål"],
  [
    292460006,
    "Konsultasjon, ovenfor individer, gruppeprosesser og på organisasjonsnivå",
  ],
  [292460007, "Bemanning, seleksjon og rekruttering"],
  [292460008, "Annen type aktivitet (Spesifiser)"],
  [292460009, "Teamutvikling"],
])

export const consultingsCyclePhase = new Map([
  [292460000, "Midtveis"],
  [292460001, "Mot slutten"],
])

export const CertificateTypeOfCertificate = new Map([
  [292460000, "EuroPsy Basic"],
  [292460002, "EuroPsy Specialist Certificate in Work & Organisation"],
  [292460003, "EuroPsy Specialist in Psychotherapy"],
])

export const CertificateFieldsOfPractice = new Map([
  [292460000, "Clinical and health"],
  [292460003, "Clinical and health, Work & Organisation"],
  [292460001, "Clinical and health, Education"],
  [292460004, "Clinical and health, Other"],
  [292460002, "Other"],
])

export const professionallyActiveStatus = new Map([
  [292460000, "Yrkesaktiv"],
  [292460001, "Ikke yrkesaktiv"],
  [292460002, "Arbeiddsøkende"],
])

export const workcategory = new Map([
  [292460000, "Ansatt"],
  [292460001, "Privatpraktiserende"],
  [292460002, "Selvstendig næringsdrivende"],
])

export const typePrivatepractitioner = new Map([
  [292460000, "Privatpraksis i enkeltpersonsforetak"],
  [292460001, "Privatpraksis som ansatt i mitt eget AS"],
])
